.container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: relative;
  margin-left: 1em;
  width: 100%;
}

.headline-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}

.company-navigation {
  padding: 1em;
  border: 2px solid #5a5a5a;
  color: #5a5a5a;
  background: none;
  font-size: 0.75em;
}

.company-container {
  width: 70%;
  height: 2em;
  text-align: center;
  background-color: none;
  margin: 1em;
  border: 0.1em solid #5a5a5a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.star-container {
  border-radius: 100%;
  height: 1em;
  width: 1em;
  background-color: none;
  border: 0.05em solid #5a5a5a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.star-svg {
  width: 1em;
  height: 1em;
  border-radius: 100%;
}
.star-svg path{
  width: 1em;
  height: 1em;
  fill: #5a5a5a;
}
.arrow-company-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}


.content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.experience-grid {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 25em 20em;
  justify-content: start;
  grid-column-gap: 5em;
  width: 100%;
  font-size: medium;
  margin-left: 5em;
}

.experience-grid .box {
  display: flex;
  justify-content: start;
  
}

.position-description {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.experience-description {
  font-size: 0.5em;
  margin: 1em;
  margin-left: 5em;
  text-align: start;
  width: 90%;
}