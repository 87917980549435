@font-face {
  font-family: 'BerkeleyMono';
  src: local('BerkeleyMono-Regular'), url(./fonts/BerkeleyMono-Regular.ttf) format('truetype');

}

body {
  background-color: #EAE7DC;
}

.App {
  text-align: center;
  background-color: #EAE7DC;
  height: 99vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #E85A4F;
  width: 100%;
  font-family: 'BerkeleyMono';
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MainContainer {
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 100%
}

.NameContainer {
  align-self: end;
}

.NameContainer h2 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.CardsContainers {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  gap: 2em;
  height: 100%;
}

span {
  font-size: 0.3em;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  height: 100%;
  color: #5a5a5a;
  border: 2px solid #5a5a5a;
}