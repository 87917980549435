.main-container {
  height: 70vh;
}

.Introduction {
  align-self: start;
  text-align: start;
  padding: 2em;
}

.Introduction h6 {
  margin-top: 1em;
  margin-bottom: 1em;
}

h3 {
  margin: 0;
}

p{
  font-size: 0.5em;
}

.skillSwitcher {
  font-size: 0.5em;
  width: 20em;
  padding: 0.5em;
  border: 2px solid #5a5a5a;
  color: #5a5a5a;
  background: none;
  margin-left: 2em;
}

.icon {
  width: 2em;
  height: 2em;
  color: #5a5a5a;
}

.icon-container {
  width: 100%;
  padding: 1em;
  display: grid;
  grid-auto-flow: row;
  justify-content: space-evenly;
  align-content: space-evenly;
}

.grid-col {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-evenly;
  align-content: space-evenly;
  width: 100%;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 3em;
}

.active {
  color: #5a5a5a;
  border: 2px solid #E85A4F;
  box-shadow: 0.5rem 0.5rem #5a5a5a;
  background: #E85A4F;
}

.skill-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skill-switch-btn-container {
  display: flex;
  flex-direction: row;
}